.wrapper {
  margin-top: 100px;
}

.anchor {
  position: relative;
  top: 150px;
  left: 50px;
}

.user-popup {
  margin-top: 5px;
  right: 200px;
  min-width: 200px;
}

.user-popup-desc-text {
  font-size: 12.5px;
  font-weight: normal;
  line-height: 0.5;
}

.user-popup-avatar-container {
  padding-top: 5px;
  display: normal;
}

.user-popup-name {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;
  margin: 10px;
  break-after: always;
}

.user-popup-logout-button {
  font-size: 12px;
}

.user-popup-logout-button-container {
  margin-top: 2px;
}

.user-popup-subscriptions {
  font-size: 12px;
}

.user-popup-dark-mode-switch {
  margin-left: 10px;
}

.user-popup-dark-mode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 3px;
}
