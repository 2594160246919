@import "@progress/kendo-font-icons/dist/index.css";

/* https://thrasio.atlassian.net/browse/RD-979 */
/* stylelint-disable-next-line selector-max-type */
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* https://thrasio.atlassian.net/browse/RD-979 */
/* stylelint-disable-next-line selector-max-type */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 18px;
  margin: 0;
}

/* Make placeholder labels (which appear inside text fields) visually different than field values */
.k-floating-label-container.k-state-empty:not(:focus-within) .k-label {
  opacity: 0.4;
}
