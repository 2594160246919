.drawer-icon {
  padding-right: 5px;
}

.drawer-expanded-icon {
  position: absolute;
  right: 10px;
  padding-top: 4px;
}

.drawer-content {
  width: 80vw;
}
